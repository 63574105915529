import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DynamicCell from './DynamicCell';
const useStyles = makeStyles(theme => ({
    
  root: {
      backgroundColor: 'rgba(255,255,255,0.6)',
      width: '100%',
      overflowX: 'auto',
      
    },
    paper: {
      minHeight: 450,
      
    },
    table:{
      fontSize: '16'
    },
    heading:{
      margin: '5px'
    },
}));

const dynamicStyles = makeStyles(({
    tableHeader: props => ({
      fontSize: props.fontSize,
      margin: '5px',
      textDecoration: 'underline',
    }),
    columnHeader: props => ({
      fontSize: props.fontSize,
    }),
}));

export default function MenuItem ({index, data, width}) {

  var fSize = 16;
  if(data?.items){
    var sorted = data.items.sort((a, b) => parseFloat(a.fontSize) - parseFloat(b.fontSize));
    //console.log('SORTED ITEMS: ' + sorted.length);
    if(sorted){
      fSize = parseFloat(sorted[0].fontSize) + 2;
    }
  }

  const styleProps = {fontSize: fSize };
    const styleClass = dynamicStyles(styleProps);

    const classes = useStyles();
    const StyledTableCell = withStyles(theme => ({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);
    
    const StyledTableRow = withStyles(theme => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'transparent'
        },
      },
    }))(TableRow);

    if(data){
    return(
    <Paper className={classes.root}>
        
        <Typography className={styleClass.tableHeader} variant="body1" component="p">
          {data.category}
        </Typography>
        <Table size="small" aria-label="Beer Menu">
            <TableHead>
                <TableRow key={'header'}>
                <TableCell className={styleClass.columnHeader}>Name</TableCell>
                <TableCell className={styleClass.columnHeader}>ABV</TableCell>
                {/*<TableCell className={styleClass.columnHeader}>IBU</TableCell>*/}
                <TableCell className={styleClass.columnHeader}>Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody><GenerateRows beers={data.items} /></TableBody>
        </Table>
      </Paper>
    );
    }
    else{
      return <div />;
    }
};

const GenerateRows = props => {
if(props.beers)
{
    return props.beers.map(function(item) {
      return (
        <TableRow key={item.id}>
        <DynamicCell color={item.color} text={item.name} fontSize={item.fontSize} />
        <DynamicCell color={item.color} text={item.abv} fontSize={item.fontSize} />
        {/*<DynamicCell color={item.color} text={item.ibu} fontSize={item.fontSize} />*/}
        <DynamicCell color={item.color} text={'$' + item.price.toFixed(2).toString()} fontSize={item.fontSize} />
        </TableRow>
        );
      });
    }else{
      return <div />
    }
  
  }
