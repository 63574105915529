import React from 'react';
import { withTheme } from "@material-ui/core";
import PrintableMenuItem from './PrintableMenuItem';
import {Masonry} from 'masonic'
import HeaderCaption from './HeaderCaption';
import PouchDBAuthentication from 'pouchdb-authentication';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import './masonry.css';


PouchDB.plugin(PouchDBAuthentication);
PouchDB.plugin(PouchDBFind);
  


class MenuBoard extends React.Component {

    constructor(props){
      super(props)
      this.state = {
        products: [],
        categories: [{}],
        header:'',
        user: props.user
      }
    }

    
  
    async componentDidMount() {

        var user = {
            name: this.state.user.key,
            password: this.state.user.password
          };
          
          var pouchOpts = {
            skip_setup: true
          };
          
          var ajaxOpts = {
            ajax: {
              headers: {
                Authorization: 'Basic ' + window.btoa(this.state.user.user + ':' + this.state.user.password)
              }
            }
          };
          
          var db = new PouchDB('https://' + this.state.user.host + '/' + this.state.user.database, {
                // to disable contacting the database before logging (and show the popup)
                skip_setup: true,

                // to configure the Basic Authentication credentials
                auth: {
                    username: this.state.user.user,
                    password: this.state.user.password
                }
                });

          var local = new PouchDB(this.state.user.database);

          var allData = {header: 'Beer Menu', headerColor: withTheme, menuItems: []};
          
          await db.logIn(this.state.user.user, this.state.user.password, ajaxOpts).then(function() {
            return db.find({
                selector: {_id: 'menuDisplay'},
              });

          }).then(function(docs) {

            //console.log(docs);
            allData = docs.docs[0];

          }).catch(function(error) {

                console.error(error);
                
                this.refreshData();

                console.error('used local data instead...');

          });

          db.replicate.to(local, {
            live: true,
            retry: true,
            include_docs: true
          });

       this.setState({header: {text: allData.header, color: allData.headerColor, size: allData.headerSize}});

       this.createCategories(
           allData.menuItems.map(result => (
           {
               name: result.name,
               price: result.price,
               category: result.category,
               abv: result.abv,
               ibu: result.ibu,
               id: result.id,
               color: result.color,
               fontSize: result.fontSize
           }))
           );

        local.changes({live: true, since: 'now', include_docs: true}).on('change', (change) => {
            this.handleChange(change);
          });

    }

    handleChange(change){

        var data = change.doc;

        this.setState({header: {text: data.header, color: data.headerColor, size: data.headerSize}});

        this.createCategories(
            data.menuItems.map(result => (
            {
                name: result.name,
                price: result.price,
                category: result.category,
                abv: result.abv,
                ibu: result.ibu,
                id: result.id,
                color: result.color,
                fontSize: result.fontSize
            }))
            );
    }

    refreshData(){

        console.log('start refreshData()');

        var offlineDoc = this.state.localDb.find({
            selector: {type: 'menu'},
        });

        var data = offlineDoc.docs[0]

        this.setState({header: {text: data.header, color: data.headerColor, size: data.headerSize}});

        this.createCategories(
            data.menuItems.map(result => (
            {
                name: result.name,
                price: result.price,
                category: result.category,
                abv: result.abv,
                ibu: result.ibu,
                id: result.id,
                color: result.color,
                fontSize: result.fontSize
            }))
            );
    }

    createCategories(data){

        var cats = [];

        data.forEach(function(entry) {

            var existing = cats.filter(function (item) {
                return item.category.toLowerCase() === entry.category.toLowerCase();
            });

            if(existing === null || existing.length === 0){

                var matchingItems = data.filter(function (item) {
                    return item.category.toLowerCase() === entry.category.toLowerCase();
                });

                cats.push({category: entry.category, items: matchingItems});
                
                //console.log('Category: ' + entry.category + '  Items: ' + matchingItems.length);
            }
        });
        console.log(cats);
        this.setState({
            products: data,
            categories: cats
        });
    }

    render(){
return(
<div id="mainMenu" style={{
                        width: '297mm',
                        } }>
             <HeaderCaption header={this.state.header} />
                    <Masonry 
                        items={this.state.categories} 
                        render={PrintableMenuItem} 
                        columnGutter={3}
                        columnWidth={300} 
                        /> 
        </div>);

    }

}
export default MenuBoard;
