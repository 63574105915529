import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const dynamicStyles = makeStyles(({
    header: props => ({
      color: props.color,
      fontSize: props.size,
      margin: 'auto'
    }),
    paper: {
      padding: 5,
      textAlign: 'center',
      marginBottom: '10px',
      wdith: '100%'
    },
}));

const Header = props => {
    const styleProps = {color: props.header.color, size: props.header.size };
    const styleClass = dynamicStyles(styleProps);
  return (
    
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    >

    <Grid item xs={10} sm={10} md={6}>
    <Paper className={styleClass.paper}>
    <Typography className={styleClass.header} variant="subtitle2" gutterBottom>
    {props.header.text}
          </Typography>
          </Paper>
    </Grid>   

    </Grid> 

  );
}

export default Header;