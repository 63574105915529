
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
      width: '150px',
      display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    paper: {
        padding: 15,
        textAlign: 'center',
        width: '300px',
        height: '75px',
        marginTop: '-20px'
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      },
  }));



const WelcomePage = () => {
  /*
const classes = useStyles();
const { loading, user, loginWithRedirect } = useAuth0();


  if (loading || !user) {
    return(

        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
        >

        <Grid item xs={3}>
        <span className={classes.paper}>
        <Button
            onClick={() => loginWithRedirect({})}
            variant="contained"
            color="primary"
            startIcon={<VpnKeyOutlinedIcon />}
          >
          Please log in to continue
          </Button>
        </span>
        </Grid>   

        </Grid> 
    );
  }

const metaData = user["http://craftpos.com/app_metadata"].couchDB.host;
*/
//history.push('/menu');



  return (
    <Redirect push to="/mobile" />
  );
  
};

export default WelcomePage;