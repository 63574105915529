import React from "react";
import PouchDBAuthentication from "pouchdb-authentication";
import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import AppContext from "../appContext";
import { withTheme } from "@material-ui/core";
import _ from 'lodash';

PouchDB.plugin(PouchDBAuthentication);
PouchDB.plugin(PouchDBFind);

export default class DataProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      categories: [],
      header: "",
      user: props.user,
      isFetching: true,
    };
  }

  async componentDidMount() {
    const host = process.env.REACT_APP_HOST;
    const dbName = process.env.REACT_APP_NAME;
    const username = process.env.REACT_APP_KEY;
    const pass = process.env.REACT_APP_SECRET;

    var pouchOpts = {
      skip_setup: true,
    };

    var ajaxOpts = {
      ajax: {
        headers: {
          Authorization: "Basic " + window.btoa(username + ":" + pass),
        },
      },
    };

    var db = new PouchDB("https://" + host + "/" + dbName, {
      // to disable contacting the database before logging (and show the popup)
      skip_setup: true,

      // to configure the Basic Authentication credentials
      auth: {
        username: username,
        password: pass,
      },
    });

    var local = new PouchDB(dbName);

    var allData = {
      header: "Beer Menu",
      headerColor: withTheme,
      menuItems: [],
    };

    await db
      .logIn(username, pass, ajaxOpts)
      .then(function () {
        return db.find({
          selector: { _id: "menuDisplay" },
        });
      })
      .then(function (docs) {
        //console.log(docs);
        allData = docs?.docs[0];
      })
      .catch(function (error) {
        console.log("start refreshData()");

        var offlineDoc = this.state.localDb.find({
          selector: { type: "menu" },
        });

        var data = offlineDoc.docs[0];

        this.setState({
          header: {
            text: data.header,
            color: data.headerColor,
            size: data.headerSize,
          },
        });

        this.createCategories(
          data.menuItems.map((result) => ({
            name: result.name,
            price: result.price,
            category: result.category,
            abv: result.abv,
            ibu: result.ibu,
            id: result.id,
            color: result.color,
            fontSize: result.fontSize,
          }))
        );

        console.error("used local data instead due to error.");
      });

    db.replicate.to(local, {
      live: true,
      retry: true,
      include_docs: true,
    });

    this.setState({
      header: {
        text: allData.header,
        color: allData.headerColor,
        size: allData.headerSize,
      },
      isFetching: false,
    });

    this.createCategories(
      allData.menuItems.map((result) => ({
        name: result.name,
        price: result.price,
        category: result.category,
        abv: result.abv,
        ibu: result.ibu,
        id: result.id,
        color: result.color,
        fontSize: result.fontSize,
      }))
    );

    local
      .changes({ live: true, since: "now", include_docs: true })
      .on("change", (change) => {
        this.handleChange(change);
      });
  }

  handleChange(change) {
    if (!change?.doc) return;

    var data = change.doc;

    this.setState({
      header: {
        text: data.header,
        color: data.headerColor,
        size: data.headerSize,
      },
      isFetching: false,
    });

    this.createCategories(
      data.menuItems?.map((result) => ({
        name: result.name,
        price: result.price,
        category: result.category,
        abv: result.abv,
        ibu: result.ibu,
        id: result.id,
        color: result.color,
        fontSize: result.fontSize,
      }))
    );
  }

  refreshData() {
    console.log("start refreshData()");

    var offlineDoc = this.state.localDb.find({
      selector: { type: "menu" },
    });

    var data = offlineDoc.docs[0];

    this.setState({
      header: {
        text: data.header,
        color: data.headerColor,
        size: data.headerSize,
      },
    });

    this.createCategories(
      data.menuItems.map((result) => ({
        name: result.name,
        price: result.price,
        category: result.category,
        abv: result.abv,
        ibu: result.ibu,
        id: result.id,
        color: result.color,
        fontSize: result.fontSize,
      }))
    );
  }

  createCategories(data) {
    var cats = [];

    for(var index = 0; index < data.length; index++){
      data[index].mobileFriendlyCategory = data[index]?.category?.toString().split('*').join('');
      var existing = cats.filter(function (item) {
        return item.category.toLowerCase() === data[index].category.toLowerCase();
      });

      if (existing === null || existing.length === 0) {
        var matchingItems = data.filter(function (item) {
          return item.category.toLowerCase() === data[index].category.toLowerCase();
        });

        cats.push({ category: data[index].category, items: matchingItems });

        //console.log('Category: ' + entry.category + '  Items: ' + matchingItems.length);
      }
    }

    //console.log(data);
    this.setState({
      products: data,
      categories: cats,
    });
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
        }}
      >
        <div>{this.props.children}</div>
      </AppContext.Provider>
    );
  }
}
