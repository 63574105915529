import React, { useState } from "react";
import { useAuth0 } from "./react-auth0-spa";
import { Router, Route, Switch } from "react-router-dom";
import Profile from "./components/Profile";
import MenuView from "./components/MenuView";
import PrintView from "./components/PrintView";
import history from "./utils/history";
import PrivateRoute from "./components/PrivateRoute";
import HideableHeader from './components/HideableHeader';
import WelcomePage from './components/Welcome';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import MenuTableView from './components/MenuTableView';

const themeObject = createMuiTheme({
  palette: {
    primary: blue,
    type: 'light'
  },
});


function App() {
  const { loading } = useAuth0();

  if (loading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
        >
        <Grid item xs={3}>
        <CircularProgress color="secondary" />
        </Grid>   
        </Grid> 
    );
  }

  return (
    
    <div className="App">
      {/* Don't forget to include the history module */}
      <Router history={history}>
        <header>
          <HideableHeader />
        </header>
        <Switch>
          <Route path="/" exact component={WelcomePage} />
          <Route path="/mobile" component={MenuTableView} />
          <PrivateRoute path="/menu" component={MenuView} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/print" component={PrintView} />
        </Switch>
      </Router>
    </div>
    
  );
}

export default App;