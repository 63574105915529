import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';

export default function printDOM(){
    const input = document.getElementById('mainMenu');
    html2canvas(input)
    .then((canvas) => {
    //document.body.appendChild(canvas);
    var wid;
    var hgt;
    var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
    var hratio = hgt/wid;
    var wratio = wid/hgt;
    var doc = new jsPDF('l','mm','a4');
    var height = doc.internal.pageSize.height - 10;    
    var width = (height * wratio) - 10;
    var xPos = Math.floor((287-width)/2); //297 = A4 actual width
    doc.addImage(img,'JPEG',xPos, 5, width, height);
    doc.setFontSize(7);
    doc.setTextColor(150);
    doc.text("Created by CraftPOS on " + moment().format('L h:mm a'), 5, 5);
    doc.save('cposMenu.pdf');
    });
    }