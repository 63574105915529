import React from "react";
import { forwardRef } from 'react';
import MaterialTable, { MTableToolbar, MTableGroupbar } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import AppContext from "../appContext";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({

    link: {
      fontColor: theme.palette.primary,
    },
}));

const MenuTable = (props) => {
  const classes = useStyles();

  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


    return (
        <AppContext.Consumer>
      {(context) => 
        <MaterialTable
        icons={tableIcons}
        title=''
        columns={[
          { title: 'Style', field: 'mobileFriendlyCategory', grouping: true, defaultGroupOrder: 0,
          defaultSort: 'asc',
          },
          { title: 'Name', field: 'name', grouping: false, 
          defaultSort: 'asc',
          render: rowData => <span style={{color: rowData.color}}>{rowData.name} </span>,
          },
          { title: 'Abv', field: 'abv', grouping: false,
          render: rowData => <span style={{color: rowData.color}}>{rowData.abv + '%'} </span>,
          },
         /* { title: 'IBU', field: 'ibu', grouping: false, 
          },*/
          { title: 'Price', field: 'price', grouping: false, 
          render: rowData => <span style={{color: rowData.color}}>{formatter.format(rowData.price)}</span>
          },
        ]}
        isLoading={context.state.isFetching}
        options={{
          showTitle: false,
          searchFieldAlignment: 'left',
          filtering: false,
          pageSize: 25,
          pageSizeOptions: [25,50,100],
          toolbar: true,
          paging: true,
          grouping: true,
          headerStyle: {
            fontWeight: 'bold',
            textDecoration: 'underline',
            color: context?.state.header.color
          }
        }}
        data={context.state.products}
       /* components={{
          Groupbar: props => (
          <div style={{display: 'none'}}>
          <MTableGroupbar {...props} />
          </div>
          ),
        }}*/
        
      />
      }
    </AppContext.Consumer>
      
    );
}
export default MenuTable;