import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CardHeader } from "@material-ui/core";
import DynamicCell from './DynamicCell';
import HeaderCaption from './HeaderCaption';
const useStyles = makeStyles(theme => ({
    
  root: {
      backgroundColor: 'white',
      width: '100%',
      overflowX: 'auto',
    },
    paper: {
      minHeight: 50,
    },
    table:{
      fontSize: '16'
    },
    heading:{
      margin: '5px'
    },
    tableHeader: {
        fontSize: 16,
        margin: '3px',
        textDecoration: 'underline',
      },
      columnHeader: {
        fontSize: 14,
      },
}));

export default function PrintableMenuItem ({index, data, width}) {

    const classes = useStyles();
    const StyledTableCell = withStyles(theme => ({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);
    
    const StyledTableRow = withStyles(theme => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'transparent'
        },
      },
    }))(TableRow);

    if(data){
    return(
    <Paper className={classes.root}>
        
        <Typography className={classes.tableHeader} variant="body1" component="p">
          {data.category}
        </Typography>
        <Table size="small" aria-label="Beer Menu">
            <TableHead>
                <TableRow>
                <TableCell className={classes.columnHeader}>Name</TableCell>
                <TableCell className={classes.columnHeader}>ABV</TableCell>
                {/*<TableCell className={styleClass.columnHeader}>IBU</TableCell>*/}
                <TableCell className={classes.columnHeader}>Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody><GenerateRows beers={data.items} /></TableBody>
        </Table>
      </Paper>
    );
    }
    else{
      return <div />;
    }
};


const GenerateRows = props => {
if(props.beers)
{
    return props.beers.map(function(item) {
      return (
        <TableRow key={item.id}>
        <DynamicCell color={item.color} text={item.name} fontSize={12} />
        <DynamicCell color={item.color} text={item.abv} fontSize={12} />
        {/*<DynamicCell color={item.color} text={item.ibu} fontSize={item.fontSize} />*/}
        <DynamicCell color={item.color} text={'$' + item.price.toFixed(2).toString()} fontSize={12} />
        </TableRow>
        );
      });
    }else{
      return <div />
    }
  
  }
