import React from "react";
import MenuTable from "./MenuTable";
import DataProvider from "../services/dataProvider";
import Grid from '@material-ui/core/Grid';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import TableHeader from './TableHeader';

function MenuTableView(props) {

  const theme = createMuiTheme({
    palette: {
      primary: blue,
      //secondary: green,
      //type: 'dark',
    },
  });

  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <DataProvider>
        <div style={{ padding: 10 }}>
        <Grid
        style={{ marginTop: "75px" }}
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      >
         <Grid style={{width: '100%'}} item xs={10}>
          <TableHeader />
        </Grid>
        <Grid style={{width: '100%'}} item xs={12} md={12} lg={6} xl={6}>
          <MenuTable />
        </Grid>
      </Grid>
        </div>
    </DataProvider>
    </ThemeProvider>
    
  );
}

export default MenuTableView;
