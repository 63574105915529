import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const dynamicStyles = makeStyles(({
    tableCell: props => ({
      color: props.color,
      fontSize: props.fontSize
    }),
}));

const DynamicCell = props => {
    const styleProps = {color: props.color, fontSize: props.fontSize };
    const styleClass = dynamicStyles(styleProps);

    const StyledTableCell = withStyles(theme => ({
        /*head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
          },*/
   
      }))(TableCell);
      
      const StyledTableRow = withStyles(theme => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: 'transparent'
          },
        },
      }))(TableRow);

  return (

    <StyledTableCell className={styleClass.tableCell}>{props.text}</StyledTableCell>

  );
}

export default DynamicCell;