import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useAuth0 } from "../react-auth0-spa";
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuBoardPrintable from './MenuBoardPrintable';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import printDOM from '../utils/pdfUtils';

function PrintView(props) {
  const { loading, user } = useAuth0();

  if (loading || !user) {
    return(
<Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
        >

        <Grid item xs={3}>
        <CircularProgress color="secondary" />
        </Grid>   

        </Grid> 
    );
  }

  /*
  const host = user["http://craftpos.com/app_metadata"].couchDB.host;
  const dbName = user["http://craftpos.com/app_metadata"].couchDB.name;
  const username = user["http://craftpos.com/app_metadata"].couchDB.key;
  const pass = user["http://craftpos.com/app_metadata"].couchDB.password;
*/

const host = process.env.REACT_APP_HOST;
const dbName = process.env.REACT_APP_NAME;
const username = process.env.REACT_APP_KEY;
const pass = process.env.REACT_APP_SECRET;

var userObj = {host: host, user: username, password: pass, database: dbName};

  return (

    <Grid
        container
        spacing={5}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '75vh'}}
        >

        <Grid item xs={4}>
        <Button onClick={() => printDOM()} variant="contained" color="primary" disableElevation startIcon={<PictureAsPdfIcon />}>
        Export
        </Button>
        </Grid>   
        <Grid item xs={12}>
        <MenuBoardPrintable user={userObj}  />
        </Grid>   
        </Grid> 
        
        
    
    
  );
}

export default PrintView;
