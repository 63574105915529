import React from "react";
import MenuBoard from "./MenuBoard";
import DataProvider from '../services/dataProvider';


function MenuView(props) {

  const host = process.env.REACT_APP_HOST;
  const dbName = process.env.REACT_APP_NAME;
  const username = process.env.REACT_APP_KEY;
  const pass = process.env.REACT_APP_SECRET;

  var userObj = {
    host: host,
    user: username,
    password: pass,
    database: dbName,
  };

  return (
    <DataProvider>
      <MenuBoard />
    </DataProvider>
  );
}

export default MenuView;
