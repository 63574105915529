// src/components/Profile.js

import React, { Fragment } from "react";
import { useAuth0 } from "../react-auth0-spa";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }));

const Profile = () => {
const classes = useStyles();
const { loading, user } = useAuth0();

  if (loading || !user) {
    return <div>Loading...</div>;
  }

const metaData = user["http://craftpos.com/app_metadata"].couchDB.host;

console.log(user);
  return (
    <Fragment>

    <Avatar alt={user.name} src={user.picture} className={classes.large} />
      
      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <p>{metaData}</p>
      
    </Fragment>
  );
};

export default Profile;