
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppContext from "../appContext";
import Typography from '@material-ui/core/Typography';

const TableHeader = () => {

    return (
        <AppContext.Consumer>
      {(context) => 
       <Typography variant="h5" gutterBottom style={{textAlign: 'center', color: context?.state.header.color}}>
       {context?.state.header.text}
     </Typography>
      
      }
    </AppContext.Consumer>
    );
};

export default TableHeader;