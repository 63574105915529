import React from "react";
import { withTheme } from "@material-ui/core";
import MenuItem from "./categoryBox";
import { Masonry } from "masonic";
import HeaderCaption from "./HeaderCaption";
import PouchDBAuthentication from "pouchdb-authentication";
import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import Grid from "@material-ui/core/Grid";
import Loading from "./Loading";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./MenuStyle.css";
import AppContext from "../appContext";
//PouchDB.plugin(PouchDBAuthentication);
//PouchDB.plugin(PouchDBFind);

class MenuBoard extends React.Component {
/*
  async componentDidMount() {
    var user = {
      name: this.state.user.key,
      password: this.state.user.password,
    };

    var pouchOpts = {
      skip_setup: true,
    };

    var ajaxOpts = {
      ajax: {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(this.state.user.user + ":" + this.state.user.password),
        },
      },
    };

    var db = new PouchDB(
      "https://" + this.state.user.host + "/" + this.state.user.database,
      {
        // to disable contacting the database before logging (and show the popup)
        skip_setup: true,

        // to configure the Basic Authentication credentials
        auth: {
          username: this.state.user.user,
          password: this.state.user.password,
        },
      }
    );

    var local = new PouchDB(this.state.user.database);

    var allData = {
      header: "Beer Menu",
      headerColor: withTheme,
      menuItems: [],
    };

    await db
      .logIn(this.state.user.user, this.state.user.password, ajaxOpts)
      .then(function () {
        return db.find({
          selector: { _id: "menuDisplay" },
        });
      })
      .then(function (docs) {
        //console.log(docs);
        allData = docs.docs[0];
      })
      .catch(function (error) {
        console.log("start refreshData()");

        var offlineDoc = this.state.localDb.find({
          selector: { type: "menu" },
        });

        var data = offlineDoc.docs[0];

        this.setState({
          header: {
            text: data.header,
            color: data.headerColor,
            size: data.headerSize,
          },
        });

        this.createCategories(
          data.menuItems.map((result) => ({
            name: result.name,
            price: result.price,
            category: result.category,
            abv: result.abv,
            ibu: result.ibu,
            id: result.id,
            color: result.color,
            fontSize: result.fontSize,
          }))
        );

        console.error("used local data instead due to error.");
      });

    db.replicate.to(local, {
      live: true,
      retry: true,
      include_docs: true,
    });

    this.setState({
      header: {
        text: allData.header,
        color: allData.headerColor,
        size: allData.headerSize,
      },
      isFetching: false,
    });

    this.createCategories(
      allData.menuItems.map((result) => ({
        name: result.name,
        price: result.price,
        category: result.category,
        abv: result.abv,
        ibu: result.ibu,
        id: result.id,
        color: result.color,
        fontSize: result.fontSize,
      }))
    );
    //console.log(this.state.localDb);
    local
      .changes({ live: true, since: "now", include_docs: true })
      .on("change", (change) => {
        this.handleChange(change);
      });
  }
*/
  /*
handleChange(change) {
    if (!change?.doc) return;

    var data = change.doc;

    this.setState({
      header: {
        text: data.header,
        color: data.headerColor,
        size: data.headerSize,
      },
      isFetching: false,
    });

    this.createCategories(
      data.menuItems?.map((result) => ({
        name: result.name,
        price: result.price,
        category: result.category,
        abv: result.abv,
        ibu: result.ibu,
        id: result.id,
        color: result.color,
        fontSize: result.fontSize,
      }))
    );
  }
*/
/*
  refreshData() {
    console.log("start refreshData()");

    var offlineDoc = this.state.localDb.find({
      selector: { type: "menu" },
    });

    var data = offlineDoc.docs[0];

    this.setState({
      header: {
        text: data.header,
        color: data.headerColor,
        size: data.headerSize,
      },
    });

    this.createCategories(
      data.menuItems.map((result) => ({
        name: result.name,
        price: result.price,
        category: result.category,
        abv: result.abv,
        ibu: result.ibu,
        id: result.id,
        color: result.color,
        fontSize: result.fontSize,
      }))
    );
  }
*/
/*
  createCategories(data) {
    var cats = [];

    data.forEach(function (entry) {
      var existing = cats.filter(function (item) {
        return item.category.toLowerCase() === entry.category.toLowerCase();
      });

      if (existing === null || existing.length === 0) {
        var matchingItems = data.filter(function (item) {
          return item.category.toLowerCase() === entry.category.toLowerCase();
        });

        cats.push({ category: entry.category, items: matchingItems });

        //console.log('Category: ' + entry.category + '  Items: ' + matchingItems.length);
      }
    });
    console.log(cats);
    this.setState({
      products: data,
      categories: cats,
    });
  }
*/

  render() {
    return(
    <AppContext.Consumer>
      {(context) => {
        let toRender;
        if (context.state.isFetching) {
          toRender = (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid item xs={10}>
                <Typography variant="body1" gutterBottom>
                  Loading all of the goodness...
                </Typography>
                <Loading />
              </Grid>
            </Grid>
          );
        } else {
          toRender = (
            <div>
              <HeaderCaption header={context.state.header} />
              <div style={{ marginLeft: "35px", marginRight: "35px" }}>
                <Masonry
                  items={context.state.categories}
                  render={MenuItem}
                  columnGutter={5}
                  columnWidth={350}
                />
              </div>
            </div>
          );
        }
        return <div>{toRender}</div>;
      }}
    </AppContext.Consumer>);
  }
}
export default MenuBoard;
