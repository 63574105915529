import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../images/logo.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import history from "../utils/history";
import TvIcon from "@material-ui/icons/Tv";
import PrintIcon from "@material-ui/icons/Print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import Drawer from "@material-ui/core/Drawer";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import purple from '@material-ui/core/colors/purple';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "0px",
  },
  root2: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  logo: {
    display: "block",
    margin: "0 auto",
    paddingLeft: theme.spacing(7),
    height: "25px",
    width: "auto",
    filter: "brightness(0) invert(1)",
  },
}));

const HideableHeader = (props) => {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [state, setState] = React.useState({
    top: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  var isAdmin = false;
  if (user)
    isAdmin = user["http://craftpos.com/app_metadata"].permissions.isAdmin;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({top: open });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const unAuthList = () => (

      <AppBar position="fixed" color="primary">
        <Toolbar variant="dense">
          <img src={logo} className={classes.logo} alt="Draft Menu" />

          {!isAuthenticated && (
            <div className={classes.root2}>
              <Tooltip title="Menu">
                <IconButton
                  color="secondary"
                  onClick={handleClick}
                  aria-controls="main-menu"
                  aria-haspopup="true"
                  aria-label="Menu"
                >
                  <MoreVertIcon fontSize="large" style={{ color: purple[50] }} />
                </IconButton>
              </Tooltip>
            </div>
          )}

          <StyledMenu
            id="main-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                loginWithRedirect({});
              }}
            >
              <IconButton color="secondary" aria-label="info">
                <ExitToAppIcon />
              </IconButton>
              Login
            </MenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>
  );

  const fullList = () => (
    <div
      className={classes.root}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <AppBar position="fixed" color="primary">
        <Toolbar variant="dense">
          {!isAuthenticated && (
            <div className={classes.root2}>
              <Button color="inherit" onClick={() => loginWithRedirect({})}>
                Log in
              </Button>
            </div>
          )}

          {isAuthenticated && (
            <Tooltip title="Log out">
              <IconButton
                edge="start"
                onClick={() => logout()}
                color="inherit"
                aria-label="menu"
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          )}
          {isAuthenticated && (
            <span>
              <Tooltip title="View full-sized menu">
                <IconButton
                  edge="start"
                  onClick={() => goToMenu()}
                  color="inherit"
                  aria-label="menu"
                >
                  <TvIcon />
                </IconButton>
              </Tooltip>
            </span>
          )}
          {isAdmin && (
            <Tooltip title="Load printer-friendly version">
              <IconButton
                edge="start"
                onClick={() => goToPrintPage()}
                color="inherit"
                aria-label="menu"
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}

          <img src={logo} className={classes.logo} alt="Draft Menu" />
        </Toolbar>
      </AppBar>
    </div>
  );

  function goToMenu() {
    history.push("/menu");
  }
  function goToProfile() {
    history.push("/profile");
  }
  function goToPrintPage() {
    history.push("/print");
  }
  function goToHome() {
    history.push("/");
  }
  function printDOM() {
    const input = document.getElementById("mainMenu");
    html2canvas(input).then((canvas) => {
      //document.body.appendChild(canvas);
      var wid;
      var hgt;
      var img = canvas.toDataURL(
        "image/png",
        (wid = canvas.width),
        (hgt = canvas.height)
      );
      var hratio = hgt / wid;
      var wratio = wid / hgt;
      var doc = new jsPDF("l", "mm", "a4");
      var height = doc.internal.pageSize.height - 10;
      var width = height * wratio - 10;
      var xPos = Math.floor((287 - width) / 2); //297 = A4 actual width
      doc.addImage(img, "JPEG", xPos, 5, width, height);
      doc.setFontSize(7);
      doc.setTextColor(150);
      doc.text("Created by CraftPOS on " + moment().format("L h:mm a"), 5, 5);
      doc.save("cposMenu.pdf");
    });
  }
  return (
    <div>
      {isAuthenticated && (
        <div>
          <Tooltip title="Menu">
            <Fab
              size="small"
              onClick={toggleDrawer(true)}
              color="primary"
              aria-label="add"
            >
              <MenuIcon />
            </Fab>
          </Tooltip>
          <Drawer
            anchor="top"
            open={state.top}
            onClose={toggleDrawer(false)}
          >
            {fullList()}
          </Drawer>
        </div>
      )}
      {!isAuthenticated && <div>{unAuthList()}</div>}
    </div>
  );
};

export default HideableHeader;
